import Vue from 'vue'
import App from './app.component.vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';

// import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-tft/theme/index.css';

// internal libs
import router from './router';
import store from './store'
import VueMeta from 'vue-meta'
import vuescroll from 'vuescroll';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faDiscord, faInstagram, faReddit } from  '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCoffee, faTwitter, faDiscord, faInstagram, faReddit)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(vuescroll, {
  ops: {
    rail: {
    background: '#49390c',
    opacity: 0.2,
    size: '7px',
    specifyBorderRadius: false,
    gutterOfEnds: null,
     gutterOfSide: '2px',
    keepShow: false},
    bar: {
      background: '#666',
      size: '7px',
      keepShow: true
    }
  },
  // customize component name, default -> vueScroll
});

Vue.router = router;
new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')