
import classesPopup from '../../shared/classes-popup/classes-popup.component.vue'
import classesData from './../../../data/classesDeep.json'
export default {
    name: 'classesPage',
    components: {
      classesPopup,
    },
    
    data() {
      return {
        dataAll: {
          "classes":classesData,
        },
        // list: [] ,
        // nameOb: [],
        // test: null,
        // i:null,
        dialogTableVisible: false,
        listView: false,
        listIcon: 'el-icon-s-fold',
        toggleName: 'List'
      };
      
      
    },
    mounted(){
    //    this.$store.dispatch('GET_ORIGIN_LIST').then(originList => {
    //     this.dataAll.origins = originList
    //     console.log(this.dataAll);
       
    // });
    //   this.$store.dispatch('GET_CHAMPION_LIST').then(championList => {
    //     this.dataAll.champions = championList
        
    // });
    
    // this.dataAll.classes = classesData;

 
    },
    methods: {
      toggleList() {
        this.listView = this.listView  ? false : true;
        this.listIcon = this.listView ? "el-icon-menu" : "el-icon-s-fold";
        this.toggleName = this.listView ? "Grid" : "List";
      }
      // getChamps: function() {
      
      //   const championNames = (Object.keys(this.dataAll.champions))
      //   const classesData = (Object.keys(this.dataAll.classes))
      //     // for (var i = 0; this.dataAll.length; i++) {
      //       this.i++
      //       for (const champs of championNames) {
              
      //         const classes = this.dataAll.champions[champs].class
      //         const champions = this.dataAll.champions[champs].key
      //         this.list = {champions}
              
      //         this.nameOb = classes
      //         for (const classeskey of classesData) {
      //         this.dataAll.test = Object.assign({champions ,classes})
             
      //         }
      //       }
      // },

    },

    metaInfo: {
      title: 'TeamFight Tactics Classes Cheat Sheet - TabTFT',
      meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: "TeamFight Tactics Classes Cheat Sheet - TabTFT"
      },
      {
        vmid: 'og:description',
        property:'og:description',
        content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
      },
      {
        vmid: "og:site_name",
        propertyname: "og:site_name",
        content: "TabTFT",
      },
      {
        vmid: "og:type",
        property: "og:type",
        content: "website",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
      },



      // ----------------TWITTER----------------
      {
        property: "twitter:title",
        content: "TeamFight Tactics Classes Cheat Sheet - TabTFT"
        
      },
      {
        property:'twitter:description',
        content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
      },
      {
        propertyname: "twitter:site",
        content: "@TabTFT",
      },
      {
        property: "twitter:creator",
        content: "@TabTFT",
      },
      {
        property: "twitter:img",
        content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
      },
      {
        property: "twitter:card",
        content: "summary",
      },
    ],
  }
    
    };