import originPopup from '../../shared/origin-popup/origin-popup.component.vue'
import originData from './../../../data/origins.json'
export default {
    name: 'originsPage',
    components: {
      originPopup
    },
    
    data() {
      return {
        dataAll: {
          "origins": originData,
        },
        dialogTableVisible: false,
        listView: false,
        listIcon: 'el-icon-s-fold',
        toggleName: 'List'
      };
      
    },
    methods: {
      toggleList() {
        this.listView = this.listView  ? false : true;
        this.listIcon = this.listView ? "el-icon-menu" : "el-icon-s-fold";
        this.toggleName = this.listView ? "Grid" : "List";
      }
      },


      
    metaInfo: {
      title: 'TeamFight Tactics Origins Cheat Sheet - TabTFT',
      meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: "TeamFight Tactics Origins Cheat Sheet - TabTFT"
      },
      {
        vmid: 'og:description',
        property:'og:description',
        content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
      },
      {
        vmid: "og:site_name",
        propertyname: "og:site_name",
        content: "TabTFT",
      },
      {
        vmid: "og:type",
        property: "og:type",
        content: "website",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
      },



      // ----------------TWITTER----------------
      {
        property: "twitter:title",
        content: "TeamFight Tactics Origins Cheat Sheet - TabTFT"
        
      },
      {
        property:'twitter:description',
        content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
      },
      {
        propertyname: "twitter:site",
        content: "@TabTFT",
      },
      {
        property: "twitter:creator",
        content: "@TabTFT",
      },
      {
        property: "twitter:img",
        content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
      },
      {
        property: "twitter:card",
        content: "summary",
      },
    ],
  }
    };