import { render, staticRenderFns } from "./origins-page.component.vue?vue&type=template&id=aea3bb18&"
import script from "./origins-page.component.js?vue&type=script&lang=js&"
export * from "./origins-page.component.js?vue&type=script&lang=js&"
import style0 from "./origins-page.component.css?vue&type=style&index=0&lang=css&"
import style1 from "../../shared/origin-popup/origin-popup.component.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports