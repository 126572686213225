import { render, staticRenderFns } from "./item-builder-horizontal-page.component.vue?vue&type=template&id=31684002&scoped=true&"
import script from "./item-builder-horizontal-page.component.js?vue&type=script&lang=js&"
export * from "./item-builder-horizontal-page.component.js?vue&type=script&lang=js&"
import style0 from "./../../shared/item-builder-small/item-builder-small.component.css?vue&type=style&index=0&id=31684002&scoped=true&lang=css&"
import style1 from "./item-builder-horizontal-page.component.css?vue&type=style&index=1&id=31684002&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31684002",
  null
  
)

export default component.exports