import classesData from './../../../data/classesDeep.json'
import itemBuilderSmall from './../../shared/item-builder-small/item-builder-small.component.vue'

export default {
    name: 'classesPopup',
    components: {
      itemBuilderSmall
    },
    data() {
      return {
        paramsID: null,
        queryCHAMP: null,
        isCollapse: true,
        icon: 'el-icon-s-unfold',
        originChamps: null,
        activeItem:'',
        dataAll: {
          "classes":classesData,
        },
      }
    },
    mounted(){
      // this.dataAll.classes = originData;
      this.originChamps = this.getOrigin(this.dataAll.classes,this.paramsID);
      
    },
 
    watch: {
      getUrlQuery(){
      },
      changeCarousel(){},
      loadCarousel(){},
      
      paramsID: {
        handler: function() {
          this.originChamps = this.getOrigin(this.dataAll.classes,this.paramsID);
          this.changeCarousel(this.queryCHAMP);
        },
        deep: true
      }
    },
    computed: {
      
      getUrlQuery: function () {
      
        this.resetQuery();
        this.paramsID = this.$route.query.id;
        if (this.$route.query.champ){
          this.queryCHAMP = this.$route.query.champ;
          this.activeItem = this.queryCHAMP ;
          return this.$route.query.champ;
          
        }
        return this.$route.query.id;
      },
      
    },
      methods: {
        changeCarousel(index) {
          this.$refs.carousel.setActiveItem(index);
          this.activeItem = index
          
        },
        loadCarousel() {
          this.$refs.carousel.setActiveItem(this.activeItem);
          
        },
        changeLevel() {
          this.$refs.levelcarousel.next();
          
        },
        toggle(){
          this.icon = this.isCollapse ? "el-icon-s-fold" : "el-icon-s-unfold",
          this.isCollapse = this.isCollapse ? false : true;
      },
      resetQuery() {
        
        this.paramsID = null;
        this.queryCHAMP = null;
      },
      getOrigin(obj, prop) {
        return obj[prop];
    },
    toUppercase(heading) {
      const headingWord = heading.toString()
      const headingLetter = heading.toString().charAt(0).toUpperCase();
      return headingLetter + headingWord.slice(1);
    }
  }
      
}
