export default {
    data() {
      return {
        name: 'mobileNavigation',
            icon: 'el-icon-s-unfold'
      };
    },
    methods: {
      toggle(){
        this.icon = this.isCollapse ? "el-icon-s-fold" : "el-icon-s-unfold"
      }
    }
  }
 