import itemBuildsData from './../../../data/itemBuilds.json'
export default {
    name: 'itemBuilderSmall',
    data() {
      return {
        itemsAll: {
          "itemsBuilds": itemBuildsData,
         
        },
        selected: 'bfsword',
        loaded: false,
        itemInfo:null,
        i:null,
      };
      
      
    },
    mounted(){

      // console.log(this.findById(this.itemsAll.items, name));
    },

    watch: {
      loadData(){},
      items(){},
 
    },

    methods: {
      itemsBonus(obj, value) {
        this.itemInfo = (this.findById(obj, value).buildsInto)
      },
      
      
findById(obj, value) {
  var result;
  for (var p in obj) {
      if (obj.value === value) {
          return obj;
      } else {
          if (typeof obj[p] === 'object') {
              result = this.findById(obj[p], value);
              if (result) {
          
                  return result
              }
          }
      }
  }
  return result;
}

    },


    
    
    };
