import Vuex from 'vuex'
import Vue from 'vue'
import OriginService from './services/origins.service'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      originList: null,
      championList: null,
    },
    getters: {
        ORIGINS: state => {
          return state.originList;
      },
      CHAMPION: state => {
        return state.championList;
    }
    },
    mutations: {
        SET_ORIGIN_LIST: (state, payload) => {
          state.originList = payload;
        },
        SET_CHAMPION_LIST: (state, payload) => {
          state.championList = payload;
        }
      },

    actions: {
        // gets all addresses that are stored in the Database
        GET_ORIGIN_LIST: (context) => {
          return OriginService.getOriginList().then(async payload => {
            await context.commit("SET_ORIGIN_LIST", payload);
            return payload;
          });
        },
        GET_CHAMPION_LIST: (context) => {
          return OriginService.getChampionList().then(async payload => {
            await context.commit("SET_CHAMPION_LIST", payload);
            return payload;
          });
        },
      }
});

export default store;