import originPopup from '../../shared/origin-popup/origin-popup.component.vue'
import originsPage from '../../pages/origins-page/origins-page.component.vue'
import classesPage from '../../pages/classes-page/classes-page.component.vue'
import itemBuilderHorizontalPage from '../../pages/item-builder-horizontal-page/item-builder-horizontal-page.component.vue'
export default {
    name: 'cheetPage',
    components: {
      originsPage,
      originPopup,
      classesPage,
      itemBuilderHorizontalPage
    },
    
    data() {
      return {

            activeName: 'first'
          };
        },
        methods: {
          handleClick(tab) {
            return tab
          }
        },

        metaInfo: {
            title: 'TFT Cheat Sheet, Builds, Origins, Classes - TabTFT',
            meta: [
            {
              vmid: "og:title",
              property: "og:title",
              content: "TFT Cheat Sheet - TabTFT"
            },
            {
              vmid: 'og:description',
              property:'og:description',
              content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
            },
            {
              vmid: "og:site_name",
              propertyname: "og:site_name",
              content: "TabTFT",
            },
            {
              vmid: "og:type",
              property: "og:type",
              content: "website",
            },
            {
              vmid: "og:image",
              property: "og:image",
              content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
            },



            // ----------------TWITTER----------------
            {
              property: "twitter:title",
              content: "TFT Cheat Sheet - TabTFT"
              
            },
            {
              property:'twitter:description',
              content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
            },
            {
              propertyname: "twitter:site",
              content: "@TabTFT",
            },
            {
              property: "twitter:creator",
              content: "@TabTFT",
            },
            {
              property: "twitter:img",
              content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
            },
            {
              property: "twitter:card",
              content: "summary",
            },
          ],
        }
    };
