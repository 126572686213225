import VueRouter from 'vue-router';
import cheetPage from './components/pages/cheet-page/cheet-page.component.vue';
import originsPage from './components/pages/origins-page/origins-page.component.vue'
// import itemBuilderPage from './components/pages/item-builder-page/item-builder-page.component.vue'
import itemBuilderHorizontalPage from './components/pages/item-builder-horizontal-page/item-builder-horizontal-page.component.vue'
import itemBuilderSmall from './components/shared/item-builder-small/item-builder-small.component.vue'
import classesPage from './components/pages/classes-page/classes-page.component.vue'
import aboutPage from './components/pages/about-page/about-page.component.vue'
import privacyPolicyPage from './components/pages/privacy-policy-page/privacy-policy-page.component.vue'
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*', component: cheetPage
    },
    {
      path: '/',
      name: 'Home',
      component: cheetPage,
    },
    
    {
      path: '/origins',
      name: 'Origins',
      component: originsPage,
    },
    {
      path: '/classes',
      name: 'Classes',
      component: classesPage,
    },
    {
      path: '/items',
      name: 'Items',
      component: itemBuilderHorizontalPage,
    },
    {
      path: '/items-small',
      component: itemBuilderSmall,
    },
    {
      path: '/privacy',
      component: privacyPolicyPage,
    },
    {
      path: '/about',
      component: aboutPage,
    }
  ]
  
});

export default router;