import itemBuildsData from './../../../data/itemBuilds.json'
export default {
    name: 'itemBuilderHorizontalPage',
    data() {
      return {
        itemsAll: {
          "itemsBuilds": itemBuildsData,
         
        },
        selected: 0,

      };
      
      
    },
    mounted(){
   
    },
   
    metaInfo: {
      title: 'TeamFight Tactics Item Builder Cheat Sheet - TabTFT',
      meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: "TeamFight Tactics Item Builder Cheat Sheet - TabTFT"
      },
      {
        vmid: 'og:description',
        property:'og:description',
        content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
      },
      {
        vmid: "og:site_name",
        propertyname: "og:site_name",
        content: "TabTFT",
      },
      {
        vmid: "og:type",
        property: "og:type",
        content: "website",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
      },



      // ----------------TWITTER----------------
      {
        property: "twitter:title",
        content: "TeamFight Tactics Item Builder Cheat Sheet - TabTFT"
        
      },
      {
        property:'twitter:description',
        content: "TeamFight Tactics cheat sheet! Have everything one tab away from your lol game. Builds, Origins, Classes, and Item Builders!",   
      },
      {
        propertyname: "twitter:site",
        content: "@TabTFT",
      },
      {
        property: "twitter:creator",
        content: "@TabTFT",
      },
      {
        property: "twitter:img",
        content: "https://tabtft.com/img/tft/tabtft-logo-dark.png",
      },
      {
        property: "twitter:card",
        content: "summary",
      },
    ],
  }
    
  };
