export default {
    name: 'aboutPage',

data() {
    return {

    };
    
    
  },
  mounted(){
 
  },
}