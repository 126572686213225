import Axios from 'axios';
const OriginService = {
  getOriginList() {
    return Axios.get('./data/origins.json').then(async response => {
      if (response.status === 200 || response.status === 201) {
        const payload  = (response.data);
        return payload;
      }
    });
    },
    getChampionList() {
        return Axios.get('https://solomid-resources.s3.amazonaws.com/blitz/tft/data/champions.json').then(async response => {
            if (response.status === 200 || response.status === 201) {
            const payload  = (response.data);
            return payload;
            }
        });
        },
    getItems() {
        return Axios.get('https://solomid-resources.s3.amazonaws.com/blitz/tft/data/items.json').then(async response => {
            if (response.status === 200 || response.status === 201) {
            const payload  = (response.data);
            return payload;
            }
        });
        }
}
export default OriginService;