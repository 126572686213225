export default {
    data() {
      return {
        name: 'SideNavigation',
            isCollapse: true,
            icon: 'el-icon-s-unfold'
      };
    },
    methods: {
      toggle(){
        this.icon = this.isCollapse ? "el-icon-s-fold" : "el-icon-s-unfold",
        this.isCollapse = this.isCollapse ? false : true;
      }
    }
  }
 