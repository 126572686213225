import cheetPage from './components/pages/cheet-page/cheet-page.component.vue';
import SideNavigation from './components/side-navigation/side-navigation.component.vue';
import mobileNavigation from './components/mobile-navigation/mobile-navigation.component.vue';
import adCanvas from './components/shared/ad-canvas/ad-canvas.component.vue';
import vuescroll from 'vuescroll';
export default {
  name: 'app',
  
  components: {
   vuescroll,
   SideNavigation,
   mobileNavigation,
   cheetPage,
   adCanvas
  },
  mounted(){
    
  },
  computed: {
    
  },
  methods: {

  },

  
};




